// Functions used in the image measurements page

import { PointGeometry, SliceOrientation, SlicePositionsCyl, SlicePositionsXyz, dimensions } from "../types";

export const scaledMouseLocation = (
    event: React.MouseEvent,
    scaledDimensions: dimensions,
    rawImageDimensions: dimensions,
    scale: number,
    svgRef: React.RefObject<HTMLInputElement>,
    imagePadding: { horizontal: number, vertical: number }
) => {
    /**
    * Function that takes a mouse event, the scaled and raw image dimensions and the svg of reference to calculate
    * the location of the mouse event in the image coordinate system
    */
    const rect = svgRef?.current?.getBoundingClientRect();
    if (rect === undefined) return ({ x: 0, y: 0 })
    return {
        x: (event?.clientX - rect.x) /
            scale * rawImageDimensions.width /
            (scaledDimensions.width)
            - imagePadding.horizontal,
        y: (event?.clientY - rect.y) /
            scale * rawImageDimensions.height /
            (scaledDimensions.height)
            - imagePadding.vertical
    }
}




/**
* @description: Takes the position of the other slice, and returns a line object used to show the guide line
*/
export const calculateOrientationGuide = (
    slicePositions: SlicePositionsCyl | SlicePositionsXyz,
    sliceOrientation: SliceOrientation,
    rawImageDimensions: dimensions,
    voxel_size_mm: number,
    padding: { horizontal: number, vertical: number },
    minSlicePositions: SlicePositionsCyl | SlicePositionsXyz,
) => {
    if (sliceOrientation === SliceOrientation.AXIAL && 'radial' in slicePositions && 'radial' in minSlicePositions) {
        return [{
            end: { x: 0 + padding.horizontal, y: rawImageDimensions.height - (slicePositions.radial - minSlicePositions.radial) / voxel_size_mm + padding.vertical },
            start: { x: rawImageDimensions.width + padding.horizontal, y: rawImageDimensions.height - (slicePositions.radial - minSlicePositions.radial) / voxel_size_mm + padding.vertical },
            label: 'Radial'
        }]
    }
    if (sliceOrientation === SliceOrientation.XY && 'xz' in slicePositions && 'yz' in slicePositions
        && 'xz' in minSlicePositions && 'yz' in minSlicePositions
    ) {
        return [{
            end: { x: 0 + padding.horizontal, y: rawImageDimensions.height - (slicePositions.xz - minSlicePositions.xz) / voxel_size_mm + padding.vertical },
            start: { x: rawImageDimensions.width + padding.horizontal, y: rawImageDimensions.height - (slicePositions.xz - minSlicePositions.xz) / voxel_size_mm + padding.vertical },
            label: 'XZ'
        },
        {
            start: { x: slicePositions.yz / voxel_size_mm + padding.horizontal, y: 0 + padding.vertical },
            end: { x: slicePositions.yz / voxel_size_mm + padding.horizontal, y: rawImageDimensions.height + padding.vertical },
            label: 'YZ'
        },

        ]
    }
    if (sliceOrientation === SliceOrientation.XZ && 'xy' in slicePositions && 'yz' in slicePositions
        && 'xy' in minSlicePositions && 'yz' in minSlicePositions
    ) {
        return [{
            end: { x: 0 + padding.horizontal, y: rawImageDimensions.height - (slicePositions.xy - minSlicePositions.xy) / voxel_size_mm + padding.vertical },
            start: { x: rawImageDimensions.width + padding.horizontal, y: rawImageDimensions.height - (slicePositions.xy - minSlicePositions.xy) / voxel_size_mm + padding.vertical },
            label: 'XY'
        },
        {
            start: { x: (slicePositions.yz - minSlicePositions.yz) / voxel_size_mm + padding.horizontal, y: 0 + padding.vertical },
            end: { x: (slicePositions.yz - minSlicePositions.yz) / voxel_size_mm + padding.horizontal, y: rawImageDimensions.height + padding.vertical },
            label: 'YZ'
        }
        ]
    }
    if (sliceOrientation === SliceOrientation.YZ && 'xy' in slicePositions && 'xz' in slicePositions
        && 'xy' in minSlicePositions && 'xz' in minSlicePositions
    ) {
        return [{
            start: { x: (slicePositions.xz - minSlicePositions.xz) / voxel_size_mm + padding.horizontal, y: 0 + padding.vertical },
            end: { x: (slicePositions.xz - minSlicePositions.xz) / voxel_size_mm + padding.horizontal, y: rawImageDimensions.height + padding.vertical },
            label: 'XZ'
        },
        {
            end: { x: 0 + padding.horizontal, y: rawImageDimensions.height - (slicePositions.xy - minSlicePositions.xy) / voxel_size_mm + padding.vertical },
            start: { x: rawImageDimensions.width + padding.horizontal, y: rawImageDimensions.height - (slicePositions.xy - minSlicePositions.xy) / voxel_size_mm + padding.vertical },
            label: 'XY'
        }
        ]
    }
    if (sliceOrientation === SliceOrientation.RADIAL && 'axial' in slicePositions) {
        // assume 0 degrees is perfectly horizontal? check assumption
        const start = { x: rawImageDimensions.width / 2 + padding.horizontal, y: 0 + padding.vertical }
        const end = { x: rawImageDimensions.width / 2 + padding.horizontal, y: rawImageDimensions.height + padding.vertical }
        // Calculate the center of the line
        const centerX = (start.x + end.x) / 2;
        const centerY = (start.y + end.y) / 2;

        // Convert theta to radians
        const radians = (Math.PI / 180) * slicePositions.axial;

        // Calculate the new coordinates for start and end points after rotation
        const startX = centerX + (start.x - centerX) * Math.cos(radians) - (start.y - centerY) * Math.sin(radians);
        const startY = centerY + (start.x - centerX) * Math.sin(radians) + (start.y - centerY) * Math.cos(radians);
        const endX = centerX + (end.x - centerX) * Math.cos(radians) - (end.y - centerY) * Math.sin(radians);
        const endY = centerY + (end.x - centerX) * Math.sin(radians) + (end.y - centerY) * Math.cos(radians);

        return [{
            start: { x: startX, y: startY },
            end: { x: endX, y: endY },
            label: 'Axial'
        }];
    }
    return []
}

export const calculateLineLength = (line: {
    start: PointGeometry
    end: PointGeometry
}, voxelSize: number) => {
    const xDiff = line.start.x - line.end.x;
    const yDiff = line.start.y - line.end.y;
    const length = Math.sqrt(xDiff * xDiff + yDiff * yDiff);
    return length * voxelSize;
}

export const calculatePolygonArea = (points: PointGeometry[], voxelSize: number) => {
    let area = 0;
    for (let i = 0; i < points.length; i++) {
        const currentPoint = points[i];
        const nextPoint = points[(i + 1) % points.length]; // Wrap around to the first point
        area += (currentPoint.x * nextPoint.y) - (nextPoint.x * currentPoint.y);
    }
    return Math.abs(area) / 2 * voxelSize * voxelSize;
}

export function calculateAngleBetweenPoints(pointA: PointGeometry, pointB: PointGeometry, pointC: PointGeometry): number {
    const vectorAB = { x: pointB.x - pointA.x, y: pointB.y - pointA.y };
    const vectorBC = { x: pointC.x - pointB.x, y: pointC.y - pointB.y };
    const dotProduct = (vectorAB.x * vectorBC.x) + (vectorAB.y * vectorBC.y);
    const magnitudeAB = Math.sqrt((vectorAB.x ** 2) + (vectorAB.y ** 2));
    const magnitudeBC = Math.sqrt((vectorBC.x ** 2) + (vectorBC.y ** 2));
    const cosTheta = dotProduct / (magnitudeAB * magnitudeBC);
    // Ensure cosTheta is within the valid range [-1, 1] to avoid Math.acos returning NaN
    const safeCosTheta = Math.max(-1, Math.min(1, cosTheta));
    return 180 - Math.acos(safeCosTheta) * (180 / Math.PI); // Convert radians to degrees
}

export const calculateRadiusLength = (line: {
    start: PointGeometry
    end: PointGeometry
}, voxelSize: number) => {
    const xDiff = line.start.x - line.end.x;
    const yDiff = line.start.y - line.end.y;
    const radius = Math.sqrt(Math.pow(xDiff, 2) + Math.pow(yDiff, 2));
    return radius * voxelSize;
}



export const highlightById = (id: number, color: string, highlightedId: number) => {
    if (id === highlightedId) {
        return color.slice(0, -2)
    }
    return color
}
