import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Button, Card, Checkbox, CircularProgress, FilledInput, FormGroup, Icon, InputLabel, Typography } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { ChangeEvent, useState } from 'react';
import { useAuthTokenAndAccessApi } from '../../auth/authHooks';
import { PageOptions } from '../../types';
import { ApiEndpoints } from "../../utils/apiUtils";
import { TermsOfServiceVersions } from './termsLibrary';

enum AcceptingTermsState {
    SUCCESS,
    FALSE,
    LOADING,
    ERROR
}

export default function TosAcceptor() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [termsAccepted, setTermsAccepted] = useState(false)
    const { postData, user } = useAuthTokenAndAccessApi()
    const [acceptanceState, setAcceptanceState] = useState(AcceptingTermsState.FALSE)
    const userAcceptsTerms = async () => {
        setAcceptanceState(AcceptingTermsState.LOADING)
        if (user) {
            const signData = {
                "first_name": firstName,
                "last_name": lastName,
                "email": user.email,
                "date": new Date().toISOString(),
                "version": TermsOfServiceVersions.latest
            }
            try {
                const response = await postData(
                    ApiEndpoints.ACCEPT_TOS,
                    signData,
                )
                if (response.status === 200) {
                    setAcceptanceState(AcceptingTermsState.SUCCESS)
                    await setTimeout(() => { window.location.reload() }, 1500)
                } else {
                    setAcceptanceState(AcceptingTermsState.ERROR)
                }
            } catch (error) {
                setAcceptanceState(AcceptingTermsState.ERROR)
            }
        }
    }

    const openTermsPageNewTab = () => {
        const baseUrl = process.env.REACT_APP_API_BASEURL as string
        // TODO: I'm re-using an envar, so this is sort of clumsy but fixable later
        const version = TermsOfServiceVersions.latest
        if (process.env.REACT_APP_API_BASEURL === "http://localhost:5000") {
            return "http://localhost:3000/?page=" + PageOptions.TERMS + "&tos=" + version
        }
        return baseUrl + "/?page=tos&tos=" + version
    }
    const handleTermsCheckbox = () => {
        setTermsAccepted(!termsAccepted)
    }

    const handleFirstNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFirstName(event.target.value);
    };
    const handleLastNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setLastName(event.target.value);
    };

    const submitButtonDisabled = () => {
        return !termsAccepted ||
            [AcceptingTermsState.LOADING, AcceptingTermsState.SUCCESS, AcceptingTermsState.ERROR]
                .includes(acceptanceState) ||
            firstName === "" ||
            lastName === ""
    }
    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
        >
            <Card sx={{ m: 5, width: 700 }}>
                <CardContent>
                    <Typography variant="h5" sx={{ flexGrow: 1, mb: 2 }}>
                        Welcome to Glimpse!
                    </Typography>
                    <Typography variant="body1" sx={{ mt: 2 }}>
                        Please enter your name and accept the terms of service to continue.
                    </Typography>
                    <FormGroup sx={{ m: 2 }}>
                        <InputLabel htmlFor="component-outlined">First Name</InputLabel>
                        <FilledInput
                            id="component-outlined"
                            onChange={handleFirstNameChange}
                        />
                        <InputLabel htmlFor="component-outlined" sx={{ mt: 2 }}>Last Name</InputLabel>
                        <FilledInput
                            id="component-outlined"
                            onChange={handleLastNameChange}
                        />
                    </FormGroup>
                    <Grid container sx={{ m: 1, alignItems: "center" }}>
                        <Checkbox onChange={handleTermsCheckbox}
                        />
                        Accept the
                        <Link href={openTermsPageNewTab()} target="_blank" sx={{ ml: 1 }}>
                            Terms of Service.
                            <OpenInNewIcon style={{ fontSize: 14 }} />
                        </Link>
                    </Grid>
                    <Grid>
                        <Button disabled={submitButtonDisabled()} variant="contained" onClick={userAcceptsTerms} sx={{ width: 150 }}>
                            Submit
                            {acceptanceState === AcceptingTermsState.LOADING && (
                                <CircularProgress size={24} sx={{ position: 'absolute' }} />
                            )}
                            {acceptanceState === AcceptingTermsState.SUCCESS && (
                                <Icon sx={{ position: 'absolute', mb: 1 }}>
                                    <CheckIcon color="success" />
                                </Icon>
                            )}
                        </Button>
                        {acceptanceState === AcceptingTermsState.ERROR && (
                            <>
                                <Typography variant="body1" color={"error"}>
                                    <Icon sx={{ mr: 1 }}>
                                        <ErrorIcon color="error" />
                                    </Icon>
                                    Something went wrong please refresh and try again.
                                </Typography>
                            </>
                        )
                        }
                    </Grid>
                    <Typography variant="body1" sx={{ mt: 2 }}>
                        Please email (tos@glimp.se) if you have any questions.
                    </Typography>
                </CardContent>
            </Card>
        </Grid >
    );
}
