import { useAuth0 } from '@auth0/auth0-react';
import axios, { AxiosRequestConfig } from 'axios';
import { ApiEndpoints, ParamsType } from '../utils/apiUtils';

export function useAuthTokenAndAccessApi() {
    // Centralized place for our authentication logic and API calls
    const {
        isLoading,
        isAuthenticated,
        getIdTokenClaims,
        user,
        loginWithRedirect,
        logout
    } = useAuth0();

    // TODO: hardcode this to be true for other deployments?
    const isVerified = async () => {
        return await getIdTokenClaims().then((claims) => claims ? claims.email_verified : false);
    }

    const fetchData = async (
        endpoint: ApiEndpoints,
        params: ParamsType = {},
        axiosOptions: Partial<AxiosRequestConfig> = {}
    ) => {
        const token = await getIdTokenClaims().then((claims) => claims ? claims.__raw : null);
        if (!token) { throw new Error('Token is not available'); }
        try {
            const config = {
                headers: {
                    Authorization: "Bearer " + token,
                },
                baseURL: process.env.REACT_APP_API_BASEURL as string,
                params: params,
                ...axiosOptions, // other options like responseType, onDownloadProgress
            };
            const response = await axios.get(endpoint, config);
            return response;
        } catch (err) {
            console.error('Error fetching data:', err);
            throw err;
        }
    };

    const sendData = async (
        endpoint: ApiEndpoints,
        data: any = {},
        method: 'POST' | 'PUT',
        params: ParamsType = {},
    ) => {
        const token = await getIdTokenClaims().then((claims) => claims ? claims.__raw : null);
        if (!token) throw new Error('Token is not available');
        try {
            const config: AxiosRequestConfig = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                baseURL: process.env.REACT_APP_API_BASEURL as string,
                params: params,
            };

            const response = await axios({
                url: endpoint,
                method: method,
                data: data,
                ...config
            });
            return response;
        } catch (err) {
            console.error(`Error ${method.toLowerCase()}ing data:`, err);
            throw err;
        }
    };

    const postData = (endpoint: ApiEndpoints, data: any = {}) =>
        sendData(endpoint, data, 'POST');
    const putData = (endpoint: ApiEndpoints, data: any = {}, params: ParamsType = {}) =>
        sendData(endpoint, data, 'PUT', params);

    return {
        fetchData,
        postData,
        putData,
        isLoading,
        isAuthenticated,
        user,
        isVerified,
        loginWithRedirect,
        logout
    };
}
