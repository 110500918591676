import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import { Card, Typography } from '@mui/material';
import Icon from '@mui/material/Icon';
import { useEffect, useRef, useState } from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import {
    AnnotationShapeList, ManualMeasurementOptions, ManualMeasurementResults,
    MeasurementStatus,
    PointGeometry, ScanList, SliceOrientation, SlicePositionsCyl,
    SlicePositionsXyz, SliceRow, dimensions,
} from '../../types';
import { calculateAngleBetweenPoints, calculateLineLength, calculateOrientationGuide, calculatePolygonArea, calculateRadiusLength } from '../../utils/imageMeasurements';
import { doubleClick, mouseDown, mouseMove } from '../../utils/sliceImageUtils';
import AnnotationLayer from './AnnotationLayer';
import DownloadButtons from './DownloadButtons';
import ImageControlFilterCard, { ClickAndDragOptions } from './ImageControlFilterCard';
import ScaleBar from './ScaleBar';

export default function SliceImage(
    { visibleImage,
        panelWidth,
        panelHeight,
        slicePositions,
        sliceOrientation,
        minSlicePositions,
        scanData,
        visibleSliceData,
        transformWrapperProps,
        imageDimensions,
        imageMeasurementPadding,
        allMeasurements,
        updateManualMeasurements,
        annotationList,
        measurementStatus,
        setMeasurementStatus
    }:
        {
            visibleImage: string,
            panelWidth: number,
            panelHeight: number,
            slicePositions: SlicePositionsCyl | SlicePositionsXyz,
            sliceOrientation: SliceOrientation
            minSlicePositions: SlicePositionsCyl | SlicePositionsXyz,
            scanData: ScanList,
            visibleSliceData: SliceRow,
            transformWrapperProps: {
                initialScale: number,
                minScale: number,
                initialPositionX: number,
                initialPositionY: number
            }
            imageDimensions: { height: number, width: number }
            imageMeasurementPadding: { horizontal: number, vertical: number },
            allMeasurements: ManualMeasurementResults[],
            updateManualMeasurements: (measurements: ManualMeasurementResults[]) => void,
            annotationList: AnnotationShapeList[]
            measurementStatus: MeasurementStatus,
            setMeasurementStatus: (status: MeasurementStatus) => void
        }
) {
    // State
    const [clickAndDragMode, setClickAndDragMode] = useState(ClickAndDragOptions.PAN);
    const [zoomLevel, setZoomLevel] = useState(transformWrapperProps.initialScale);
    const [latestMeasurementId, setLatestMeasurementId] = useState(100);
    const [activeManualMeasurement, setActiveManualMeasurement] = useState(ManualMeasurementOptions.LINE);

    const voxelSize = visibleSliceData.voxel_size_mm

    // extra - CSS filters contrast and color invert:
    const [invertFilter, setInvertFilter] = useState(false);
    const [sepiaFilter, setSepiaFilter] = useState(false);
    const [contrastFilterValue, setContrastFilterValue] = useState(1);
    const [brightnessFilterValue, setBrightnessFilterValue] = useState(1);
    const [showOrientationGuides, setShowOrientationGuides] = useState(false);
    const svgRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        cancelMeasurement(allMeasurements, measurementStatus);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeManualMeasurement, visibleImage])

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                cancelMeasurement(allMeasurements, measurementStatus);
                setClickAndDragMode(ClickAndDragOptions.PAN);
            }
            if (event.key === 'm') {
                setClickAndDragMode(ClickAndDragOptions.MEASURE);
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allMeasurements]);

    const imageFilterCss = () => {
        let filterCss = "";
        if (invertFilter) filterCss = filterCss.concat(" invert(1)")
        if (sepiaFilter) filterCss = filterCss.concat(" sepia(1)")
        filterCss = filterCss.concat(" contrast(").concat(contrastFilterValue.toString()).concat(")");
        filterCss = filterCss.concat(" brightness(").concat(brightnessFilterValue.toString()).concat(")");
        return filterCss;
    }

    const cancelMeasurement = (allMeasurements: ManualMeasurementResults[], measurementStatus: MeasurementStatus) => {
        if (allMeasurements.length === 0 || measurementStatus === MeasurementStatus.COMPLETE) return;
        setMeasurementStatus(MeasurementStatus.COMPLETE);
        updateManualMeasurements(allMeasurements.slice(0, allMeasurements.length - 1));
    }

    const instantiateMeasurement = (activeManualMeasurement: ManualMeasurementOptions, lastPoint: PointGeometry) => {
        // Need to instantiate measurements differently. lines start and end together
        //  but areas and angles start with one point and then add more. with no measurement
        const id = latestMeasurementId + 1;
        setLatestMeasurementId(id);
        setMeasurementStatus(MeasurementStatus.IN_PROGRESS);
        switch (activeManualMeasurement) {
            case ManualMeasurementOptions.LINE:
                const lineGeometry: ManualMeasurementResults = {
                    id: id,
                    result: 0,
                    shapeType: ManualMeasurementOptions.LINE,
                    coordinates: [lastPoint, lastPoint],
                    display: true,
                };
                updateManualMeasurements([...allMeasurements, lineGeometry]);
                break;
            case ManualMeasurementOptions.POLYGON:
                const areaGeometry: ManualMeasurementResults = {
                    id: id,
                    result: 0,
                    shapeType: ManualMeasurementOptions.POLYGON,
                    coordinates: [lastPoint],
                    display: true,
                };
                updateManualMeasurements([...allMeasurements, areaGeometry]);
                break;
            case ManualMeasurementOptions.ANGLE:
                setMeasurementStatus(MeasurementStatus.ANGLE_STARTED)
                const angleGeometry: ManualMeasurementResults = {
                    id: id,
                    result: 0,
                    shapeType: ManualMeasurementOptions.ANGLE,
                    coordinates: [lastPoint],
                    display: true,
                };
                updateManualMeasurements([...allMeasurements, angleGeometry]);
                break;
            case ManualMeasurementOptions.RADIUS:
                const radiusGeometry: ManualMeasurementResults = {
                    id: id,
                    result: 0,
                    shapeType: ManualMeasurementOptions.RADIUS,
                    coordinates: [lastPoint, lastPoint],
                    display: true,
                };
                updateManualMeasurements([...allMeasurements, radiusGeometry]);
                break;
            default:
                break;
        }
    }

    const updateMeasurementState = (activeManualMeasurement: ManualMeasurementOptions, lastPoint: PointGeometry, itemBeingEdited: ManualMeasurementResults, commit: boolean) => {
        const id = itemBeingEdited.id;
        if (!id) return;
        if (itemBeingEdited.shapeType !== activeManualMeasurement) return; // TODO: throw error?
        switch (itemBeingEdited.shapeType) {
            case ManualMeasurementOptions.LINE:
                const result = calculateLineLength({
                    start: itemBeingEdited.coordinates[0],
                    end: lastPoint
                }, voxelSize);
                const lineGeometry: ManualMeasurementResults = {
                    id: id,
                    result: result,
                    shapeType: ManualMeasurementOptions.LINE,
                    coordinates: [itemBeingEdited.coordinates[0], lastPoint],
                    display: true,
                };
                if (commit) { setMeasurementStatus(MeasurementStatus.COMPLETE) }
                updateManualMeasurements([...allMeasurements.slice(0, allMeasurements.length - 1), lineGeometry]);

                break;
            case ManualMeasurementOptions.POLYGON:
                const areaResult = calculatePolygonArea(itemBeingEdited.coordinates, voxelSize)
                const coordinates = itemBeingEdited.coordinates
                const areaGeometry: ManualMeasurementResults = {
                    id: id,
                    result: areaResult,
                    shapeType: ManualMeasurementOptions.POLYGON,
                    coordinates: commit || coordinates.length === 1 ? coordinates.concat(lastPoint) :
                        coordinates.slice(0, coordinates.length - 1).concat(lastPoint),
                    display: true,
                };
                updateManualMeasurements([...allMeasurements.slice(0, allMeasurements.length - 1), areaGeometry]);

                break;
            case ManualMeasurementOptions.ANGLE:
                let vertexPoint: PointGeometry | null = null;
                let endPoint: PointGeometry | null = null;
                let resultAngle = 0;
                if (measurementStatus === MeasurementStatus.ANGLE_STARTED) {
                    vertexPoint = lastPoint
                    if (commit) { setMeasurementStatus(MeasurementStatus.ANGLE_VERTEXED) }
                }
                else if (measurementStatus === MeasurementStatus.ANGLE_VERTEXED && itemBeingEdited.coordinates[1]) {
                    vertexPoint = itemBeingEdited.coordinates[1]
                    endPoint = lastPoint
                    resultAngle = calculateAngleBetweenPoints(itemBeingEdited.coordinates[0], itemBeingEdited.coordinates[1], lastPoint)
                    if (commit) { setMeasurementStatus(MeasurementStatus.COMPLETE) }
                }
                let angleCoordinates = [itemBeingEdited.coordinates[0]]
                if (vertexPoint) angleCoordinates.push(vertexPoint)
                if (endPoint) angleCoordinates.push(endPoint)
                const angleGeometry: ManualMeasurementResults = {
                    id: id,
                    result: resultAngle,
                    shapeType: ManualMeasurementOptions.ANGLE,
                    coordinates: angleCoordinates,
                    display: true,
                };
                updateManualMeasurements([...allMeasurements.slice(0, allMeasurements.length - 1), angleGeometry])
                break;
            case ManualMeasurementOptions.RADIUS:
                const radiusResult = calculateRadiusLength({
                    start: itemBeingEdited.coordinates[0],
                    end: lastPoint
                }, voxelSize);
                const radiusGeometry: ManualMeasurementResults = {
                    id: id,
                    result: radiusResult,
                    shapeType: ManualMeasurementOptions.RADIUS,
                    coordinates: [itemBeingEdited.coordinates[0], lastPoint],
                    display: true,
                };
                if (commit) { setMeasurementStatus(MeasurementStatus.COMPLETE) }
                updateManualMeasurements([...allMeasurements.slice(0, allMeasurements.length - 1), radiusGeometry]);
                break;
            default:
                break;
        }
    }


    const handleMouseDown = (event: React.MouseEvent, scaledDimensions: dimensions) => {
        mouseDown(event, scaledDimensions, imageDimensions, zoomLevel, svgRef, imageMeasurementPadding, allMeasurements,
            clickAndDragMode, measurementStatus, instantiateMeasurement, activeManualMeasurement, updateMeasurementState)
    }

    const handleMouseMove = (event: React.MouseEvent, scaledDimensions: dimensions) => {
        mouseMove(event, scaledDimensions, imageDimensions, zoomLevel, svgRef, imageMeasurementPadding, allMeasurements,
            clickAndDragMode, measurementStatus, activeManualMeasurement, updateMeasurementState)
    }


    const handleDoubleClick = (event: React.MouseEvent, scaledDimensions: dimensions) => {
        doubleClick(event, scaledDimensions, imageDimensions, zoomLevel, svgRef, imageMeasurementPadding, allMeasurements,
            clickAndDragMode, measurementStatus, activeManualMeasurement, setMeasurementStatus, updateMeasurementState)
    }


    if (visibleImage && imageDimensions && transformWrapperProps.initialScale) return (
        <Card sx={{
            width: panelWidth, height: panelHeight, backgroundColor: 'black', borderRadius: 0, p: 0, m: 0, position: 'relative'
        }}
            elevation={0}
        >
            <TransformWrapper
                initialPositionX={transformWrapperProps.initialPositionX}
                initialPositionY={transformWrapperProps.initialPositionY}
                initialScale={transformWrapperProps.initialScale}
                minScale={transformWrapperProps.minScale}
                maxScale={10}
                limitToBounds={false}
                panning={{ disabled: clickAndDragMode === ClickAndDragOptions.MEASURE }}
                doubleClick={{ disabled: true }}
                onZoom={(e) => setZoomLevel(e.state.scale)}
            >
                {({ zoomIn, zoomOut, resetTransform, centerView, ...rest }) => {
                    return (
                        <>
                            <ImageControlFilterCard
                                clickAndDragMode={clickAndDragMode}
                                setClickAndDragMode={setClickAndDragMode}
                                resetTransform={() => { setZoomLevel(transformWrapperProps.initialScale); resetTransform() }}
                                setInvertFilter={setInvertFilter}
                                invertFilter={invertFilter}
                                contrastFilterValue={contrastFilterValue}
                                setContrastFilterValue={setContrastFilterValue}
                                brightnessFilterValue={brightnessFilterValue}
                                setBrightnessFilterValue={setBrightnessFilterValue}
                                measurementType={activeManualMeasurement}
                                setActiveManualMeasurement={setActiveManualMeasurement}
                                resetAllFilters={() => {
                                    setInvertFilter(false)
                                    setSepiaFilter(false)
                                    setContrastFilterValue(1)
                                    setBrightnessFilterValue(1)
                                }}
                                hideOrientationGuides={() => setShowOrientationGuides(!showOrientationGuides)}
                            />

                            <DownloadButtons
                                visibleImage={visibleImage}
                                slicePosition={visibleSliceData.position}
                                scanData={scanData}
                                sliceOrientation={sliceOrientation}
                                imageDimensions={imageDimensions}
                                imageFilterCss={imageFilterCss}
                                annotationList={annotationList}
                            />
                            <TransformComponent >
                                <img
                                    src={visibleImage}
                                    alt="slice"
                                    style={{
                                        filter: imageFilterCss(),
                                        marginTop: imageMeasurementPadding.vertical,
                                        marginBottom: imageMeasurementPadding.vertical,
                                        marginLeft: imageMeasurementPadding.horizontal,
                                        marginRight: imageMeasurementPadding.horizontal,
                                        backgroundColor: 'black',
                                    }} />
                                <AnnotationLayer
                                    rest={rest}
                                    orientationGuides={showOrientationGuides ? calculateOrientationGuide(slicePositions, sliceOrientation, imageDimensions,
                                        voxelSize, imageMeasurementPadding, minSlicePositions) : []}
                                    zoomLevel={zoomLevel}
                                    svgRef={svgRef}
                                    mouseDown={handleMouseDown}
                                    mouseMove={handleMouseMove}
                                    doubleClick={handleDoubleClick}
                                    imageDimensions={{ height: imageDimensions.height + imageMeasurementPadding.vertical * 2, width: imageDimensions.width + imageMeasurementPadding.horizontal * 2 }}
                                    annotationList={annotationList}
                                    imageMeasurementPadding={{ horizontal: imageMeasurementPadding.horizontal, vertical: imageMeasurementPadding.vertical }}
                                />
                            </TransformComponent>
                            <ScaleBar zoomLevel={zoomLevel} voxelSize={voxelSize} />
                        </>
                    )
                }}
            </TransformWrapper>
        </Card>
    )
    return (
        <>
            <Typography variant='body2'>Something went wrong, please try reloading.</Typography>
            <Icon>
                <BrokenImageIcon />
            </Icon>
        </>
    );
}
