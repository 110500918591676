import { ClickAndDragOptions } from "../components/SliceViewerPage/ImageControlFilterCard";
import { dimensions, ManualMeasurementOptions, ManualMeasurementResults, MeasurementStatus } from "../types";
import { scaledMouseLocation } from "./imageMeasurements";

// Functions to handle mouse/key events in SliceImage

export const shiftKeyDown = (event: React.MouseEvent, xy: { x: number; y: number }, latestPoint: { x: number; y: number }) => {
    if (event.shiftKey) {
        let dx = Math.abs(xy.x - latestPoint.x);
        let dy = Math.abs(xy.y - latestPoint.y);
        if (dx > dy) {
            xy.y = latestPoint.y
        }
        else {
            xy.x = latestPoint.x
        }
    }
};


export const mouseDown = (event: React.MouseEvent, scaledDimensions: dimensions,
    imageDimensions: { height: number, width: number },
    zoomLevel: number,
    svgRef: React.RefObject<HTMLInputElement>,
    imageMeasurementPadding: { horizontal: number, vertical: number },
    allMeasurements: ManualMeasurementResults[],
    clickAndDragMode: ClickAndDragOptions,
    measurementStatus: MeasurementStatus,
    instantiateMeasurement: (measurementType: ManualMeasurementOptions, xy: { x: number; y: number }) => void,
    activeManualMeasurement: ManualMeasurementOptions,
    updateMeasurementState: (measurementType: ManualMeasurementOptions, xy: { x: number; y: number }, itemBeingEdited: ManualMeasurementResults, isEditing: boolean) => void
) => {
    if (clickAndDragMode === ClickAndDragOptions.PAN) return;
    const xy = scaledMouseLocation(event, scaledDimensions, imageDimensions, zoomLevel, svgRef, imageMeasurementPadding);

    if (event.shiftKey) {
        const latestMeasurementCoordinates = allMeasurements[allMeasurements.length - 1].coordinates;
        const latestPoint = latestMeasurementCoordinates[latestMeasurementCoordinates.length - 2];
        shiftKeyDown(event, xy, latestPoint)

    }
    if (measurementStatus === MeasurementStatus.COMPLETE) {
        instantiateMeasurement(activeManualMeasurement, xy);
    } else {
        const itemBeingEdited = allMeasurements[allMeasurements.length - 1]; // TODO use latest id state? repeated code
        updateMeasurementState(activeManualMeasurement as ManualMeasurementOptions, xy, itemBeingEdited, true);

    }
}


export const mouseMove = (
    event: React.MouseEvent,
    scaledDimensions: dimensions,
    imageDimensions: { height: number, width: number },
    zoomLevel: number,
    svgRef: React.RefObject<HTMLInputElement>,
    imageMeasurementPadding: { horizontal: number, vertical: number },
    allMeasurements: ManualMeasurementResults[],
    clickAndDragMode: ClickAndDragOptions,
    measurementStatus: MeasurementStatus,
    activeManualMeasurement: ManualMeasurementOptions,
    updateMeasurementState: (measurementType: ManualMeasurementOptions, xy: { x: number; y: number }, itemBeingEdited: ManualMeasurementResults, isEditing: boolean) => void

) => {
    if (clickAndDragMode === ClickAndDragOptions.PAN) return;
    const midMeasurement = [MeasurementStatus.IN_PROGRESS, MeasurementStatus.ANGLE_STARTED, MeasurementStatus.ANGLE_VERTEXED].includes(measurementStatus)
    if (midMeasurement) {
        if (allMeasurements.length === 0) return; // TODO: better error catching
        const xy = scaledMouseLocation(event, scaledDimensions, imageDimensions, zoomLevel, svgRef, imageMeasurementPadding);

        if (event.shiftKey) {
            const latestMeasurementCoordinates = allMeasurements[allMeasurements.length - 1].coordinates;
            const latestPoint = latestMeasurementCoordinates[latestMeasurementCoordinates.length - 2];
            shiftKeyDown(event, xy, latestPoint)
        }
        const itemBeingEdited = allMeasurements[allMeasurements.length - 1];
        updateMeasurementState(activeManualMeasurement as string as ManualMeasurementOptions, xy, itemBeingEdited, false);
    }

}



export const doubleClick = (
    event: React.MouseEvent,
    scaledDimensions: dimensions,
    imageDimensions: { height: number, width: number },
    zoomLevel: number,
    svgRef: React.RefObject<HTMLInputElement>,
    imageMeasurementPadding: { horizontal: number, vertical: number },
    allMeasurements: ManualMeasurementResults[],
    clickAndDragMode: ClickAndDragOptions,
    measurementStatus: MeasurementStatus,
    activeManualMeasurement: ManualMeasurementOptions,
    setMeasurementStatus: (status: MeasurementStatus) => void,
    updateMeasurementState: (measurementType: ManualMeasurementOptions, xy: { x: number; y: number }, itemBeingEdited: ManualMeasurementResults, isEditing: boolean) => void
) => {
    if (clickAndDragMode === ClickAndDragOptions.PAN) return;
    if (activeManualMeasurement === ManualMeasurementOptions.POLYGON) {
        if (measurementStatus === MeasurementStatus.IN_PROGRESS) {
            const xy = scaledMouseLocation(event, scaledDimensions, imageDimensions, zoomLevel, svgRef, imageMeasurementPadding);
            const itemBeingEdited = allMeasurements[allMeasurements.length - 1]; // TODO use latest id state?
            if (itemBeingEdited.id) updateMeasurementState(activeManualMeasurement as string as ManualMeasurementOptions, xy, itemBeingEdited, true);
            setMeasurementStatus(MeasurementStatus.COMPLETE);
        }
    }
}
