import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import { Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { useAuthTokenAndAccessApi } from "../auth/authHooks";
export default function UserButtonHeader() {
    const { user } = useAuthTokenAndAccessApi();

    const tooltip: string = user ? "Logged in as: " + user.email : "Not logged in";
    return (
        <Tooltip title={<Typography variant="body1">{tooltip}</Typography>}
            sx={{ mr: 1 }}
            slotProps={{
                popper: {
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 10],
                            },
                        },
                    ],
                },
            }} >
            < AccountBoxOutlinedIcon />
        </Tooltip >
    )
};
