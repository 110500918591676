import HelpIcon from '@mui/icons-material/Help';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import { Autocomplete, Backdrop, Card, FormControl, FormControlLabel, IconButton, InputLabel, ListItemText, MenuItem, Select, Stack, TextField, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material';
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";
import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import { useAuthTokenAndAccessApi } from '../../auth/authHooks';
import { LineChartData, MeasurementDimensions, ScanList } from '../../types';
import { newTabLink } from '../../utils';
import { ApiEndpoints } from '../../utils/apiUtils';
import { colorScheme } from '../../utils/colorScheme';
import { AutomatedInspectionMetrics, MetricChartPageOptions, metricDisplayUnit, selectedMetricData } from '../../utils/inspection';
import DownloadChartData from './DownloadChartData';
import OneChartLine from './OneChartLine';

export interface MetrologyDataRequest {
    metric_id: number,
    request_id: number,
    request_name: string,
    scan_id: number,
    max_value: number,
    min_value: number,
    avg_value: number,
}

export enum Granularity {
    SCAN = 'scan_id',
    REQUEST = 'request_id',
}


export default function MetrologyPage(
    {
        scanList,
        isDemoMode,
        availableMetrics
    }: {
        scanList: ScanList[],
        isDemoMode: boolean,
        availableMetrics: MeasurementDimensions[]
    }) {
    const { fetchData } = useAuthTokenAndAccessApi();
    const [sortAscending, setSortAscending] = useState(false);
    const [allMetrology, setAllMetrology] = useState<MetrologyDataRequest[]>([]);
    const [isLoading, setLoadingStatus] = useState(true)
    const [granularity, setGranularity] = useState(Granularity.SCAN)
    const [selectedMetric, setSelectedMetric] = useState<MetricChartPageOptions>(MetricChartPageOptions.CORE_CIRCULARITY_DMIN_DMAX)
    const [selectedRequests, setSelectedRequests] = useState<string[]>([]);
    const [showAvg, setShowAvg] = useState(true);
    const [showMin, setShowMin] = useState(true);
    const [showMax, setShowMax] = useState(true);
    const [resetChartZoom, setResetChartZoom] = useState(false);
    const uniqueRequests = Array.from(new Set(allMetrology.map((e) => e.request_name)))


    useEffect(() => {
        async function initialDataLoader(isDemoMode: boolean) {
            setLoadingStatus(true)
            getChartData(isDemoMode, selectedMetric)
        }
        initialDataLoader(isDemoMode)
    }, [isDemoMode]);

    async function getChartData(isDemoMode: boolean, selectedMetric: MetricChartPageOptions) {
        let endpoint = ApiEndpoints.INSPECTION_CHART_RESULTS_GENERAL
        setAllMetrology([])
        let params: any = isDemoMode ? { is_demo: true } : {}
        switch (selectedMetric) {
            case MetricChartPageOptions.CATHODE_WIDTH:
                params = { ...params, metric: AutomatedInspectionMetrics.CATHODE_WIDTH }
                break;
            case MetricChartPageOptions.ANODE_OVERHANG_ALL:
                params = { ...params, metric: AutomatedInspectionMetrics.ANODE_OVERHANG_ALL }
                break;
            case MetricChartPageOptions.ANODE_OVERHANG_TOP:
                params = { ...params, metric: AutomatedInspectionMetrics.ANODE_OVERHANG_TOP }
                break;
            case MetricChartPageOptions.ANODE_OVERHANG_BOTTOM:
                params = { ...params, metric: AutomatedInspectionMetrics.ANODE_OVERHANG_BOTTOM }
                break;
            case MetricChartPageOptions.CORE_CIRCULARITY_DMIN_DMAX:
                params = { ...params, metric: AutomatedInspectionMetrics.CORE_CIRCULARITY_DMIN_DMAX }
                break;
            case MetricChartPageOptions.CORE_AREA:
                params = { ...params, metric: AutomatedInspectionMetrics.CORE_AREA }
                break;
            case MetricChartPageOptions.CORE_CIRCULARITY_MCC:
                params = { ...params, metric: AutomatedInspectionMetrics.CORE_CIRCULARITY_MCC }
                break;
            case MetricChartPageOptions.CAN_INNER_DIAMETER_MEAN:
                params = { ...params, metric: AutomatedInspectionMetrics.CAN_INNER_DIAMETER_MEAN }
                break;
            case MetricChartPageOptions.CAN_OUTER_DIAMETER_MEAN:
                params = { ...params, metric: AutomatedInspectionMetrics.CAN_OUTER_DIAMETER_MEAN }
                break;
            case MetricChartPageOptions.ANODE_TOP_BOTTOM_ASYSMMETRY:
                params = { ...params, metric: AutomatedInspectionMetrics.ANODE_OVERHANG_ASYMMETRY }
                break;
            case MetricChartPageOptions.CAN_WALL_THICKNESS_MEAN:
                params = { ...params, metric: AutomatedInspectionMetrics.CAN_WALL_THICKNESS_MEAN }
                break;
            default:
                endpoint = ApiEndpoints.INSPECTION_CHART_RESULTS_GENERAL
        }
        try {
            const response = await fetchData(endpoint, params);
            const data = response.data as MetrologyDataRequest[]
            setAllMetrology(data)
            setSelectedRequests(Array.from(new Set(data.map((e) => e.request_name))))
            setLoadingStatus(false)
        } catch (error: any) { console.error(error) }
    }

    // TODO: this function could be golfed a bit
    function calculateRequestAggregates(data: MetrologyDataRequest[]) {
        const groupedData: { [key: number]: number[] } = {};
        const result = [];
        // Group data by request_id and calculate aggregates
        for (const item of data) {
            const key = item.request_id;
            if (!groupedData[key]) { groupedData[key] = []; }
            groupedData[key].push(item.avg_value);
        }
        // Calculate aggregates for each group
        for (const key in groupedData) {
            const values = groupedData[key];
            const id = parseInt(key);
            const sum = values.reduce((acc, val) => acc + val, 0);
            const count = values.length;
            const average = sum / count;
            const min = Math.min(...values);
            const max = Math.max(...values);
            result.push({ id, average, min, max });
        }
        return result;
    }

    const makeLabel = (granularity: keyof MetrologyDataRequest, id: number) => {
        switch (granularity) {
            case Granularity.SCAN:
                return scanList.find((e) => e.scan_id === id)?.cell_sn.toString() || ""
            case Granularity.REQUEST:
                return scanList.find((e) => e.request_id === id)?.request_name.toString() || ""
            default:
                return granularity.toString() + ': ' + id.toString()
        }
    }

    const handleSelectedMetricChange = (newValue: string) => {
        setLoadingStatus(true)
        setSelectedMetric(newValue as MetricChartPageOptions);
        getChartData(isDemoMode, newValue as MetricChartPageOptions)
    }

    const makeTooltip = (granularity: keyof MetrologyDataRequest, id: number) => {
        switch (granularity) {
            case Granularity.SCAN:
                const item = scanList.find((e) => e.scan_id === id)
                return item?.cell_model_vendor.concat(" ", item.cell_model_name) || ""
            case Granularity.REQUEST:
                return scanList.find((e) => e.request_id === id)?.cell_model_vendor.toString() || ""
            default:
                return granularity.toString() + ': ' + id.toString()
        }
    }

    // filter based on request:
    const filteredData = selectedMetricData(allMetrology, availableMetrics, selectedMetric)
        .filter((e) => selectedRequests.includes(e.request_name))
        .sort((a, b) => a.scan_id - b.scan_id)
        .sort((a, b) => a.request_id - b.request_id)

    // TODO: Could consolidate and rethink filtering and transform here.
    const lineChartData = granularity === Granularity.REQUEST ?
        calculateRequestAggregates(filteredData).map((e) => ({
            max: e.max,
            min: e.min,
            average: e.average,
            xLabel: makeLabel(granularity, e.id),
            tooltip: makeTooltip(granularity, e.id),
            id: e.id,
        }))
        : filteredData.map((e) => ({
            max: e.max_value,
            min: e.min_value,
            average: e.avg_value,
            xLabel: makeLabel(granularity, e[granularity]), // check this
            tooltip: makeTooltip(granularity, e[granularity]),
            id: e[granularity],
        }))
    // TODO: clean this up later when the dust settles
    const sortedData = (chartData: LineChartData[]) => {
        if (sortAscending) return chartData.sort(
            (a, b) => a.average - b.average
        ).map((e) => ({ ...e }));
        else return chartData
    }


    const handleSelectAllRequests = () => {
        if (uniqueRequests.length !== selectedRequests.length) {
            setSelectedRequests(uniqueRequests);
        } else {
            setSelectedRequests([]);
        }
    };

    const handleCheckboxChange = (option: string) => {
        const currentIndex = selectedRequests.indexOf(option);
        const newSelectedOptions = [...selectedRequests];
        if (currentIndex === -1) {
            newSelectedOptions.push(option);
        } else {
            newSelectedOptions.splice(currentIndex, 1);
        }
        setSelectedRequests(newSelectedOptions);
    };

    const renderValueRequest = () => {
        if (selectedRequests.length === 0) {
            return 'None';
        } else if (selectedRequests.length === uniqueRequests.length) {
            return `All Batches (${selectedRequests.length} batches, ${scanList.length} scans)`;
        } else {
            return `Selected ${selectedRequests.length} of ${uniqueRequests.length} batches`;
        }
    };

    return (
        <>
            <Typography variant="h5" component="div" color='#FFF' sx={{ flexGrow: 1, pt: 1 }}>
                Automated Inspection Review
            </Typography>
            {/* TODO: break apart into child components */}

            <>
                View by:
                <ToggleButtonGroup value={granularity} sx={{ p: 1, mr: 5 }} size='small'>
                    <ToggleButton value={Granularity.SCAN} onClick={() => setGranularity(Granularity.SCAN)}>Scan</ToggleButton>
                    <ToggleButton value={Granularity.REQUEST} onClick={() => setGranularity(Granularity.REQUEST)}>Batch</ToggleButton>
                </ToggleButtonGroup>
                Sort by:
                <ToggleButtonGroup value={sortAscending ? 'value' : "id"} sx={{ p: 1, mr: 8 }} size='small'>
                    <ToggleButton value={'id'} onClick={() => setSortAscending(false)}>Scan Order</ToggleButton>
                    <ToggleButton value={'value'} onClick={() => setSortAscending(true)}>Value</ToggleButton>
                </ToggleButtonGroup>
                <Grid container spacing={1} sx={{ mt: 1 }} >
                    <Grid item xs={4}>
                        <Autocomplete
                            options={Object.values(MetricChartPageOptions).map((e) => e)}
                            renderInput={(params) => <TextField {...params} label="Metric:" variant="outlined" />}
                            value={selectedMetric}
                            onChange={(event, newValue) => {
                                if (newValue) handleSelectedMetricChange(newValue)
                            }}
                            disableClearable
                        />
                    </Grid>
                    <Grid item xs={4} >
                        <FormControl fullWidth >
                            <InputLabel >Batch:</InputLabel>
                            <Select
                                label="Batch"
                                multiple
                                value={selectedRequests}
                                onChange={() => { }}
                                renderValue={renderValueRequest}
                            >
                                <MenuItem >
                                    <Checkbox
                                        checked={selectedRequests.length === uniqueRequests.length}
                                        onChange={handleSelectAllRequests} />
                                    <ListItemText primary={"All  (".concat(scanList.length.toString(), " scans)")} />
                                </MenuItem>
                                {uniqueRequests.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        <Checkbox checked={selectedRequests.indexOf(option) > -1} onChange={() => handleCheckboxChange(option)} />
                                        <ListItemText primary={
                                            option.concat(" (", scanList.filter((e) => e.request_name === option).length.toString(), " scans)")
                                        } />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Card elevation={0} sx={{ borderRadius: 0, mt: 2 }}>
                    <Stack
                        justifyContent="center"
                        direction={'row'}
                        sx={{ mt: 1 }}
                    >
                        {/* TODO: move to component */}
                        <FormControlLabel control={<Checkbox
                            defaultChecked
                            sx={{ [`&, &.${checkboxClasses.checked}`]: { color: colorScheme.average, } }}
                            onChange={() => setShowAvg(!showAvg)} />} label="Average" />
                        <FormControlLabel control={<Checkbox
                            defaultChecked
                            sx={{ [`&, &.${checkboxClasses.checked}`]: { color: colorScheme.max, } }}
                            onChange={() => setShowMax(!showMax)} />} label="Max" />
                        <FormControlLabel control={<Checkbox
                            defaultChecked
                            sx={{ [`&, &.${checkboxClasses.checked}`]: { color: colorScheme.min, } }}
                            onChange={() => setShowMin(!showMin)} />} label="Min" />
                        {/* {sortedData(lineChartData).length < 100 &&
                                <FormControlLabel control={<Checkbox
                                    defaultChecked
                                    sx={{ [`&, &.${checkboxClasses.checked}`]: { color: colorScheme.average.concat('80'), } }}
                                    onChange={() => setShowRange(!showRange)} />} label="Range" />
                            } */}
                        <Tooltip title={
                            <Typography variant='body2'>
                                Reset chart zoom
                            </Typography>
                        }>
                            <IconButton onClick={() => setResetChartZoom(!resetChartZoom)}>
                                <ZoomOutMapIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip placement='left' title={
                            <Typography variant='body2'>
                                Click and drag on the plot area to zoom in. Hold the shift key to pan when zoomed in.<br />
                                <br />
                                Click on a data point to view the associated scan in a new tab.
                            </Typography>
                        }>
                            <HelpIcon sx={{ mt: 1, ml: 1, position: 'absolute', right: 50 }} />
                        </Tooltip>
                        <DownloadChartData chartData={sortedData(lineChartData)} metric_name={selectedMetric} granularity={granularity} metricDisplayUnit={metricDisplayUnit(selectedMetric)} />
                    </Stack>

                    <Grid container spacing={1} direction={'row'} sx={{ width: '100%', height: 600, pb: 3, mt: 1, position: "relative" }}>
                        <OneChartLine
                            chartData={sortedData(lineChartData)}
                            idClicked={(id: number) => {
                                if (granularity === Granularity.SCAN) window.open(newTabLink(id, isDemoMode), "_blank")
                            }}
                            metricDisplayName={selectedMetric}
                            metricDisplayUnit={metricDisplayUnit(selectedMetric)}
                            xAxisTitle={granularity === Granularity.SCAN ? "Cell Serial Number" : "Batch Name"}
                            showAvg={showAvg}
                            showMin={showMin}
                            showMax={showMax}
                            resetChartZoom={resetChartZoom}
                        />
                        <Backdrop
                            open={isLoading}
                            sx={{ position: 'absolute', zIndex: 1000 }}
                        >
                            Loading...
                        </Backdrop>
                        <Backdrop
                            open={lineChartData.length === 0 && !isLoading}
                            sx={{ position: 'absolute', zIndex: 1000 }}
                        >
                            No data for the selected scans and metric.
                        </Backdrop>
                    </Grid>
                </Card>
            </>

        </>
    );
}
